import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
import { Apple } from '@styled-icons/boxicons-logos/Apple';
/*#69gfu#*/

import { Windows } from '@styled-icons/boxicons-logos/Windows';
import { Linux } from '@styled-icons/fa-brands/Linux';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <table className="table-fixed text-center max-w-3xl m-auto mt-6">
  <thead>
  <tr className="text-5xl">
    <td className="w-1/3 text-center"><Apple width='2em' className="-mt-2" mdxType="Apple" />{
              /*Mac*/
            }</td>
    <td className="w-1/3 text-center"><Linux height='1.5em' className="-mt-2 mr-2 text-gray-400" mdxType="Linux" /></td>
    <td className="w-1/3 text-center"><Windows height='1.5em' className="-mt-2 mr-1 text-gray-400" mdxType="Windows" /></td>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td className="text-center"><a href="https://codestory-releases.s3.fr-par.scw.cloud/codestory-releases/Code%20Story%202.1.0%20%28433%29.dmg" className="button">Download</a></td>
    <td className="text-center text-gray-400">Soon</td>
    <td className="text-center text-gray-400">Soon</td>
  </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      